#global-header-bar {
  @extend %site-width-container;
  height: 10px;
  background-color: #00b2e2;
  @include ie-lte(8) {
    font-size: 0;
  }
}

#global-header {
  background-color: $ros-header-colour;
  width: 100%;

  .header-wrapper {
    background-color: $ros-header-colour;
    max-width: $site-width + 30;
    margin: 0 auto;
    padding-top: 8px;
    padding-bottom: 8px;
    @extend %contain-floats;
    @include media(tablet){
      padding-left: $gutter-half;
      padding-right: $gutter-half;
    }
    @include ie-lte(8) {
      width: $site-width + 30;
    }

    .header-global {
      @extend %contain-floats;
      .header-logo {
        @extend %contain-floats;
        float: left;

        @include media(desktop){
          width: 40%;
        }
        @media screen and (max-width: 379px) {
          width: auto;
          float: none;
        }

      }
      .header-logo {
        margin: 5px 0 2px;
      }
    }
  }
  &.with-proposition {
    .header-wrapper {
      .header-global {
        @include media(desktop){
          float: left;
          width: 40%;

          .header-logo,
          .site-search {
            width: 100%;
          }
        }
      }
      .header-proposition {
        @include media(desktop){
          width: 60%;
          float: left;
        }
        @media (max-width:40.0625em) {
          .content {
            margin: 0 15px;
          }
        }
      }
    }
  }

  .header-proposition {
    padding-top: 10px;
    @include media(desktop){
      padding-top: 0;
    }
    #proposition-name {
      @include core-24;
      padding-top: 2px;
      font-weight: bold;
      color: $black;
      text-decoration: none;
    }
    a#proposition-name:hover {
      text-decoration: underline;
    }
    a.menu {
      @include core-16;
      color: $white;
      display: block;
      float: right;
      text-decoration: none;
      padding-top: 6px;
      @include media(desktop){
        display: none;
      }
      &:hover {
        text-decoration: underline;
      }
      &:after {
        display: inline-block;
        font-size: 8px;
        height: 8px;
        padding-left: 5px;
        vertical-align: middle;
        content: " \25BC";
      }
      &.js-hidden:after {
        content: " \25B2";
      }
    }
    #proposition-menu {
      margin-top: 5px;

      // Some headers (Whitehall) don't have a propsition name, offset as if they did.
      &.no-proposition-name {
        @include media(desktop){
          margin-top: 37px;
          // 37px is the amount of margin required to line up the nav list with the top of the search box
        }
      }
    }
    #proposition-link,
    #proposition-links {
      clear: both;
      @extend %contain-floats;
      margin: 2px 0 0 0;
      padding: 0;

      .js-enabled & {
        display: none;
        @include media(desktop){
          display: block;
        }
        &.js-visible {
          display: block;
        }
      }
    }

    #proposition-link {
      float: right;
      line-height: 22px;
      .js-enabled & {
        display:block;
      }
      @include media(desktop) {
        float:none;
      }
    }
  }
}

