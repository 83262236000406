$Lato: "Lato", sans-serif;
$toolkit-font-stack: $Lato !default;

@function image-url($fn) {
  @return url("images/" + $fn);
}

@import "govuk-elements";
@import "elements/govuk-template-base";

// ----------------------------------
// ROS project specific sass includes
// ----------------------------------
// RoS Styles
@import "ros/colours";
@import "ros/accessibility";
@import "ros/buttons";
@import "ros/layout";
@import "ros/header";
@import "ros/footer";
@import "ros/overrides";
@import "ros/regions";
@import "ros/lists";
