$site-width: 1280px;

%site-width-container {
  max-width: $site-width;

  @include ie-lte(8) {
    width: $site-width;
  }

  margin: 0 $gutter-half;

  @include media(tablet) {
    margin: 0 $gutter;
  }

  @include media($min-width: ($site-width + $gutter * 2)) {
    margin: 0 auto;
  }
}
@mixin grid-column($width, $full-width: tablet, $float: left) {

  @include media($full-width) {
    float: $float;
    width: percentage($width);
  }

  @include ie-lte(7) {
    width: (($site-width + $gutter) * $width) - $gutter;
  }

  padding: 0 $gutter-half;
  @include box-sizing(border-box);
}

@mixin outer-block {
  @warn "The @mixin outer-block is deprecated and should be updated to use new grid helpers";
  margin: 0 auto;
  width: auto;
  max-width: $site-width + $gutter * 2;
  @extend %contain-floats;

  @include ie-lte(8) {
    width: $site-width + 60;
  }
}
