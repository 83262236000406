/* Make skiplinks visible when they are tabbed to */

.skiplink {
  position: absolute;
  left: -9999em;
}

.skiplink:focus {
  left: 0;
  z-index: 1;
}

#skiplink-container {
  text-align: center;
  background: $black;

  div {
    text-align: left;
    margin: 0 auto;
    max-width: 1020px;
  }

  .skiplink {
    @include inline-block;
    margin: 0.75em 0 0 30px;
  }
}
