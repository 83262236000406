html {
  background-color: #dddede;
}

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  margin-right: 2px;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.hidden,
.js-enabled .js-hidden {
  display: none;
  visibility: hidden;
}

.back-to-previous {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  a {
    font-size: 16px;
    line-height: 1.25;
    text-align: right;
    background-image: file-url("icon-arrow-left.png");
    background-repeat: no-repeat;
    background-position: left 0.2em;
    padding-left: 0.75em;
  }
}

.postcode {
  text-transform: uppercase;
}

span.postcode, span.search-term {
  font-weight: bold;
  margin-right: 0.5em;
}

.postcode-prefix, .search-term-prefix {
  display: block;
  line-height: 1.31579;
  margin-bottom: 0.5em;
}

ol, ul {
  list-style: none;
}

details {
  margin-top: 10px;
}

table.vertical-labels {
  th, td {
    vertical-align: top;
  }
}

.nowrap {
  white-space: nowrap;
}

.error {
  .error-message {
    color: $error-colour;
  }
  .form-control {
    border: 4px solid $error-colour;
  }
}

.error,
.error-summary {
  border-left: 4px solid $error-colour;
  @include media(tablet) {
    border-left: 5px solid $error-colour;
  }
}

.error-summary {
  border: 4px solid $error-colour;
  @include media(tablet) {
    border: 5px solid $error-colour;
  }

  .error-summary-list {
    a {
      color: $error-colour;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.error {
  .form-date {
    .form-control {
      border: 2px solid $grey-1;
    }
    .invalid {
      border: 4px solid $error-colour;
    }
    &.invalid {
      input {
        border: 4px solid $error-colour;
      }
    }
  }
}

a[rel="external"]:after {
  content: "\00A0\00A0\00A0\00A0\00A0";
}
.external-link:after {
  content: "\00A0\00A0\00A0\00A0\00A0";
}

.maintenance-info {
  margin-top: 20px;
}

.alert__notification {
  border: 5px solid $govuk-blue;
  padding: 20px 15px 15px;
}

.llr-phase-tag {
  @include inline-block;
  margin: 0 8px 0 0;
  padding: 2px 5px 0;

  @include bold-16($line-height: 20 / 16);
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  color: $white;
  background-color: $govuk-blue;
}
