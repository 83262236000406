/* Global footer */

#footer {
  border-top: 1px solid #565a5c;
  background-color: #dddede;
  padding-top: $gutter;
  padding-bottom: $gutter *1.5;

  a {
    color: $black;
    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    margin: 0;
  }

  .footer-wrapper {
    @extend %site-width-container;
    margin: 0 auto;
    padding: 60px 1em 0 1em;
  }

  .footer-meta {

    clear: both;
    font-size: 0;
    padding-bottom: 60px;

    .copyright {

      @include core-16;

      margin: $gutter*1.5 0 0 0;
      width: 100%;
      display: block;
      text-align: center;

      @include media(tablet) {
        max-width: 135px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
        text-align: right;
      }

      @include ie-lte(7) {
        float: right;
        height: 150px;
        width: 24%;
      }

      .graphic-portcullis-large-grey {
        margin-right: $gutter *1.5;
      }

      a {
        display: block;
        text-decoration: none;
        margin-top: $gutter-half;
        background-image: url(images/copyright-logo.png);
        background-repeat: no-repeat;
        background-position: 50% 0%;
        text-align: center;
        padding: 105px 0 0 0;
      }
    }

    .footer-meta-inner {

      display: inline-block;
      vertical-align: bottom;
      width: 80%;

      ul {
        margin-bottom: $gutter;
        display: inline-block;
        li {
          @include core-16;
          display: inline-block;
          margin: 0 15px $gutter-one-third 0;
        }
      }

      .open-government-licence {
        clear: left;
        position: relative;
        padding-left: 53px;

        @include ie(6) {
          zoom: 1;
        }

        span.logo {
          position: absolute;
          left: 0;
          top: 0;
          width: 41px;
          height: 100%;

          img {
            display: block;
            width: 43px;
            height: 17px;
          }
        }

        p {
          @include core-16;
          margin: 0;
          padding-top: 0;
        }
      }
    }
  }

  @include media(tablet) {
    .footer-wrapper {
      position: relative;
    }
  }

  @include media(mobile) {
    .footer-wrapper {
      position: relative;
    }
    .footer-meta .footer-meta-inner .open-government-licence {
      padding-left: 0;
      h2 {
        position: static;
        width: auto;
        margin-bottom: 1em;
      }
    }
  }
}


