@mixin button-override($colour) {
  @include button($colour);
  @include box-sizing(border-box);
  margin: 0 0 $gutter-half 0;
  padding: em(10, 24) em(15, 24);
  vertical-align: top;
  @include bold-24;
  text-align: center;
  width: 100%;

  @include media($min-width: 420px) {
    width: inherit;
    margin-right: $gutter-half;
  }

  // Bottom edge effect
  @include box-shadow(none);
}

.button {
  @include button-override($ros-button-colour);
}

// Disabled buttons
.button[disabled="disabled"] {
  background: $ros-button-colour;
}

// Start now buttons
.button-start,
.button-get-started {
  padding: em(6) em(41) em(5) em(16);
}

// Make button look like a link
button.button-link,
button.button-link:focus,
button.button-link:active {
  background: none;
  border: none;
  display: inline;
  font: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  outline-offset: 0;
  color: $link-colour;
  cursor: pointer;
  text-decoration: underline;
}

button.button-link:active {
  color: $link-active-colour;
}

button.button-link:hover {
  color: $link-hover-colour;
}

button.button-link:focus {
  background-color: $focus-colour;
}

/* Remove extra space inside buttons in Firefox */
button.button-link::-moz-focus-inner,
button.button-link::-moz-focus-inner {
  border: none;
  padding: 0;
}
