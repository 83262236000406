.multi-select {
  border: 5px solid #DEE0E2;
  margin-bottom: 30px;
  max-height: 330px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  position: relative;

  .form-group {
    margin: 0 !important;
  }

  label {
    width: 85%;
  }
}
