.callout.callout-reference {
  background: #f8f8f8 none repeat scroll 0 0;
  border-left: 5px solid #bfc1c3;
}

.callout {
  box-sizing: border-box;
  margin: 0 0 60px;
  padding: 30px 15px;
  width: 91.6667%;
}
